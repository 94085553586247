import { apolloClient } from '@/plugins/apollo';
import {
    CLIENT_ANNUALFEE_LIST_CF_ANNUAL,
} from '@/api/graphql/confirmAnnual';
import { handlError, setCopyErrorTextCommit } from '@/constants/functions';
import gql from 'graphql-tag'

export default {
  async getClientAnnualfeeListCFAnnual({ commit, state }, obj) {
    const finishData = {
      filter: {
          ...(obj.filter ?? {}),
      },
      pagination: {
          take: obj.paginate.take,
          skip: obj.paginate.skip
      },
      orderBy: obj.orderBy
    }
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: gql`${CLIENT_ANNUALFEE_LIST_CF_ANNUAL}`,
          variables: finishData,
          fetchPolicy: 'no-cache'
        })
        .then(data => {
          commit('setTotalClientAnnualfeeListCFAnnual', data.data.clientAnnualFeeList.total)
          commit(
            'setClientAnnualfeeListCFAnnual',
            data.data.clientAnnualFeeList.items,
          );
          resolve(data);
        })
        .catch(error => {
          setCopyErrorTextCommit(commit, CLIENT_ANNUALFEE_LIST_CF_ANNUAL, finishData, error.graphQLErrors)
          handlError(commit, error.graphQLErrors);
          reject(error);
        });
    });
  },
};
